import { Button, Heading, Text, VStack } from '@chakra-ui/react';
import RequestForm from 'components/forms/RequestForm';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

const Login = ({ register, setRegister, backUrl }) => {
  const { T, locale } = useTranslation();
  return (
    <>
      <VStack align={'start'} spacing='2' textAlign='center' pb='4'>
        <Heading as='h3' fontSize={'h3'}>
          {T(`general.forms.login.${locale}`)}
        </Heading>
        <Text color='dark.600' fontSize={'16px'}>
          {T(`general.forms.have_account.${locale}`)}{' '}
          <Button
            colorScheme='linkedin'
            variant={'link'}
            onClick={() => setRegister(true)}
          >
            {T(`general.forms.register_now.${locale}`)}
          </Button>
        </Text>
      </VStack>

      <RequestForm
        type='login'
        btnText={T(`general.forms.login.${locale}`)}
        btnProps={{
          colorScheme: 'dark'
        }}
        setRegister={setRegister}
        backUrl={backUrl}
      ></RequestForm>
    </>
  );
};

export default Login;
