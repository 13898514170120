import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  VStack,
  Text,
  Heading,
  HStack,
  Box
} from '@chakra-ui/react';
import { Logo, LogoSvg } from 'assets';
import RequestForm from 'components/forms/RequestForm';
import {
  FacebookAuthButton,
  GoogleAuthButton
} from 'components/forms-elements';
import Login from './Login';
import Register from './Register';
import { hideLoginModal, useLayoutContext } from 'context/layout';
import { useRouter } from 'next/router';
import useTranslation from 'hooks/useTranslation';
const LoginModal = ({}) => {
  // const onClose = () => setIsOpen(false);
  const { T, locale } = useTranslation();
  const [register, setRegister] = useState(false);
  const { dispatch, state } = useLayoutContext();

  const router = useRouter();

  return (
    <Modal
      isOpen={state.loginModalShow}
      onClose={() => dispatch(hideLoginModal())}
    >
      <ModalOverlay />
      <ModalContent
        paddingInlineStart={'0rem !important'}
        paddingInlineEnd={'0rem !important'}
        w={{ base: '96%', md: '100%' }}
      >
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody py='2' px='0'>
          <VStack
            align={'center'}
            borderBottomWidth='1px'
            borderColor={'black'}
            pb='6'
          >
            <LogoSvg w={44} />
          </VStack>
          <VStack align='stretch' spacing={4} p='10'>
            {register ? (
              <Register
                register={register}
                setRegister={setRegister}
                backUrl={router.asPath}
              />
            ) : (
              <Login
                register={register}
                setRegister={setRegister}
                backUrl={router.asPath}
              />
            )}

            <HStack
              spacing={6}
              align='center'
              justifyContent={'center'}
              alignItems='center'
              pt='2'
            >
              <Box width={'40px'} height='2px' bg={'dark.300'} />
              <Text> {T(`general.forms.or.${locale}`)}</Text>
              <Box width={'40px'} height='2px' bg={'dark.300'} />
            </HStack>

            <VStack align={'center'} spacing='6' pt='2'>
              <Text> {T(`general.forms.log_in_with.${locale}`)}</Text>
              <HStack spacing={6}>
                <GoogleAuthButton
                  title={T(`general.forms.google.${locale}`)}
                  type={'register'}
                />
                <FacebookAuthButton
                  title={T(`general.forms.facebook.${locale}`)}
                  type={'register'}
                />
              </HStack>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
